import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { ReactNode, useState } from 'react';
import { SimpleTemplateContainer } from '..';
import { Button } from '../../atoms/button/button';
import Checkbox from '../../atoms/checkbox/checkbox';
import InputText from '../../atoms/input-text/input-text';
import { Link } from '../../atoms/link/link';
import Message from '../../molecules/message/message';
import BackLink from '../../molecules/back-link/back-link';
import {
  CreatePassword,
  MailingAddress,
  PersonalInformation,
  messages as personalInfoMessages,
} from '../../molecules/sing-up-forms';
import { signupMessages } from './sign-up';
import FormSectionHeader from '@components/molecules/form-section-header/form-section-header';
import useGuestRegistrationForm from '@hooks/use-guest-registration-form';
import { getElementError } from '@hooks/use-form-validation';
import FormErrorMessage from '@components/molecules/form-error-message/form-error-message';
import { IconUser } from '@components/atoms/icons';
import { Heading } from '@components/atoms/heading/heading';
import { passwordMessages } from '@components/molecules/input-password/input-password';
import { titleMessage } from 'pages/guest';
import { useCurrentUser } from '@hooks/use-user';
import { VerifyEmailForm } from '@components/organisms/verify-email-form/verify-email-form';

const messages = defineMessages({
  labelTax: {
    id: 'lYilE+',
    description: 'Checkbox option to select if you would like a tax receipt.',
    defaultMessage: 'Would you like a tax receipt?',
  },
  labelFirstName: {
    id: '46hFFm',
    description: 'Form label for first name',
    defaultMessage: 'First Name',
  },
  labelLastName: {
    id: '0pfqXu',
    description: 'Form label for last name',
    defaultMessage: 'Last Name',
  },
  labelEmail: {
    id: 'fZG/J7',
    description: 'Form label for email address',
    defaultMessage: 'Email',
  },
  labelEmailConfirmation: {
    id: 'cX8Et4',
    description: 'Form label for email address confrimation',
    defaultMessage: 'Email Confirmation',
  },
  headingMailingAddress: {
    id: 'I+vLO9',
    description: 'Heading for mailing address section of form registration',
    defaultMessage: 'Mailing Address',
  },
  tooltipTitleMailing: {
    id: 'Gn1QAB',
    description: 'Tooltip title for mailing address section.',
    defaultMessage: 'Why do we need this?',
  },
  labelCreateAccount: {
    id: 'A0/Zm/',
    description:
      'Checkbox to ask user if they want to convert their guest registration into a full account',
    defaultMessage:
      'Would you like to create an account along with the donation?',
  },
});

export const getDonateFlowUrl = (route: string, orgId?: any) => {
  return orgId ? `/donate/${orgId}${route}` : route;
};

const GuestRegistration = () => {
  const [callbackUrl, setCallbackUrl] = useState<string>();
  const [verifyEmail, setVerifyEmail] = useState(false);
  const {
    values,
    handleChange,
    handleSubmit,
    hasSubmitted,
    errors,
    hasErrors,
    submitError,
    isSubmitting,
  } = useGuestRegistrationForm({
    onVerifyEmail: (callbackUrl) => {
      setVerifyEmail(true);
      setCallbackUrl(callbackUrl);
    },
  });
  const getError = getElementError(errors, hasSubmitted);
  const intl = useIntl();
  const router = useRouter();
  const { data: user } = useCurrentUser();
  const { orgId } = router.query;
  return (
    <SimpleTemplateContainer isContainer>
      {verifyEmail && values.email && (
        <div className="w-full relative">
          <div className="flex justify-right md:absolute left-0 top-2">
            <BackLink href={orgId ? `/donate/${orgId}/login` : undefined} />
          </div>
          <div className="container-xs sm:max-w-sm mt-4 md:mt-0">
            <VerifyEmailForm
              showCancel={false}
              newEmail={values.email}
              onSuccess={() => {
                router.push(callbackUrl || '/account');
              }}
            />
          </div>
        </div>
      )}
      {!verifyEmail && (
        <>
          <div className="w-full text-center relative">
            <div className="flex justify-right md:absolute left-0 top-2">
              <BackLink
                href={
                  orgId
                    ? user
                      ? `/donate/${orgId}`
                      : `/donate/${orgId}/login`
                    : undefined
                }
              />
            </div>
            <div className="container-xs mt-4 md:mt-0">
              <Message
                type="info"
                className="flex items-center justify-center gap-1"
              >
                <IconUser />
                <FormattedMessage
                  id="nLI1WI"
                  defaultMessage="Already have an account? <span><a>Sign in</a>.</span>"
                  description="Message above registration form."
                  values={{
                    a: (text) => (
                      <Link
                        href={getDonateFlowUrl('/login', orgId)}
                        className="underline"
                      >
                        {text}
                      </Link>
                    ),
                  }}
                />
              </Message>
            </div>
            <Heading variant="h2" as="h1" color="nad-blue" className="my-8">
              {intl.formatMessage(titleMessage.title)}
            </Heading>
            <div className="container-xs space-y-6">
              <div>
                <Checkbox
                  label={intl.formatMessage(messages.labelTax)}
                  name="tax_receipt"
                  checked={values.tax_receipt}
                  onChange={handleChange}
                  data-testid="guest-registration-tax_receipt"
                />
              </div>
            </div>
          </div>
          <form
            method="post"
            onSubmit={handleSubmit}
            data-testid="guest-registration"
            noValidate
          >
            <div className="container-xs space-y-6 mt-6">
              {values.tax_receipt ? (
                <>
                  <PersonalInformation
                    values={values}
                    errors={errors}
                    hasSubmitted={hasSubmitted}
                    handleChange={handleChange}
                    requiredFields={[
                      'first_name',
                      'email',
                      'email_confirmation',
                    ]}
                  />
                  <FormSectionHeader
                    className="!mt-10"
                    title={intl.formatMessage(messages.headingMailingAddress)}
                    tooltipTitle={intl.formatMessage(
                      messages.tooltipTitleMailing
                    )}
                  >
                    <FormattedMessage
                      id="boHc2k"
                      defaultMessage="We provide your church with your mailing address to send your end-of-year tax receipts from your donations."
                      description="Help message for mailing address on create an account."
                    />
                  </FormSectionHeader>
                  <MailingAddress
                    values={values}
                    errors={errors}
                    hasSubmitted={hasSubmitted}
                    handleChange={handleChange}
                  />
                  <Checkbox
                    name="create_account"
                    label={intl.formatMessage(messages.labelCreateAccount)}
                    checked={values.create_account}
                    onChange={handleChange}
                    data-testid="create-account"
                  />
                  {values.create_account && (
                    <>
                      <FormSectionHeader
                        className="!mt-10"
                        title={intl.formatMessage(
                          signupMessages.headingCreatePassword
                        )}
                        tooltipTitle={intl.formatMessage(
                          signupMessages.tooltipTitlePassword
                        )}
                      >
                        {intl.formatMessage(
                          passwordMessages.passwordStrengthHelp
                        )}
                      </FormSectionHeader>
                      <CreatePassword
                        values={values}
                        errors={errors}
                        hasSubmitted={hasSubmitted}
                        handleChange={handleChange}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Message type="danger" textAlign="left">
                    <div className="space-y-2">
                      <FormattedMessage
                        id="HBV03H"
                        description="Warning message if the user does not want to receive a tax receipt."
                        defaultMessage="<p>You will not receive a tax receipt from your church if you do not provide a name and complete mailing address.</p><p>Please be prepared to keep your own records for tax purposes.</p>"
                        values={{
                          p: (text) => <div>{text}</div>,
                        }}
                      />
                    </div>
                  </Message>
                  <div className="w-full flex flex-col space-y-6 mt-6">
                    <InputText
                      error={getError('first_name')}
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      label={intl.formatMessage(messages.labelFirstName)}
                      data-testid="guest-registration-first_name"
                      autoComplete="given-name"
                    />
                    <InputText
                      error={getError('last_name')}
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      label={intl.formatMessage(messages.labelLastName)}
                      data-testid="guest-registration-last_name"
                      autoComplete="family-name"
                    />
                    <InputText
                      error={getError('email')}
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      label={intl.formatMessage(messages.labelEmail)}
                      required
                      data-testid="guest-registration-email"
                      autoComplete="email"
                    />
                    <InputText
                      error={getError('email_confirmation')}
                      name="email_confirmation"
                      value={values.email_confirmation}
                      onChange={handleChange}
                      label={intl.formatMessage(
                        messages.labelEmailConfirmation
                      )}
                      required
                      data-testid="guest-registration-email-confirmation"
                      autoComplete="email"
                    />
                    <Checkbox
                      name="accepted_privacy_policy"
                      checked={values.accepted_privacy_policy}
                      onChange={handleChange}
                      label={intl.formatMessage(
                        personalInfoMessages.labelPrivacyPolicy,
                        {
                          a: (text: ReactNode) => (
                            <a
                              href="https://adventistgiving.helpscoutdocs.com/article/84-privacy-policy"
                              target="_blank"
                              className="underline"
                              rel="noreferrer"
                            >
                              {text}
                            </a>
                          ),
                        }
                      )}
                      error={getError('accepted_privacy_policy')}
                    />
                  </div>
                </>
              )}
              <div className="!mt-10 space-y-6">
                <FormErrorMessage errors={submitError} />
                <Button
                  isLoading={isSubmitting}
                  disabled={isSubmitting || (hasSubmitted && hasErrors)}
                  width="large"
                  className="mx-auto block"
                  data-testid="guest-registration-submit"
                >
                  <FormattedMessage
                    id="KD6lRu"
                    defaultMessage="Continue"
                    description="Continue button"
                  />
                </Button>
              </div>
            </div>
          </form>
        </>
      )}
    </SimpleTemplateContainer>
  );
};
export default GuestRegistration;
