import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { getCsrfToken, signIn } from 'next-auth/react';
import { useIntl } from 'react-intl';
import {
  FormErrors,
  getIntlErrorMessage,
  handleFormChange,
  setResponseErrorMessages,
  validateEmail,
  validateFieldConfirmation,
  validatePassword,
  validateRequiredFields,
  validateMinMaxLength,
} from './use-form-validation';
import { setOfflineErrors, useOnline } from './use-online';
import { useRecaptcha } from './use-recaptcha';
import { clearCache } from './use-user';
import { clearUserSpecificSessionStorage } from './use-tithe-form';
import { RegisterUserRequest } from '@models/http/register-user-request';
import { fetchPost } from '@fetch/helpers';
import { RegisterUserResponse } from '@models/http/register-user-response';

export interface SignUpFormData
  extends Omit<RegisterUserRequest, 'code_challenge'> {
  password_confirmation: string;
}

interface ValidationParams {
  requiredFields: (keyof SignUpFormData)[];
  values: SignUpFormData;
}

const validate = ({ requiredFields, values }: ValidationParams) => {
  let errors = validateRequiredFields(requiredFields, values);
  const email = validateEmail(values.email);
  if (email) {
    errors.email = email;
  }

  const emailConfirmation = validateFieldConfirmation(
    values.email,
    values.email_confirmation
  );
  if (emailConfirmation) {
    errors.email_confirmation = emailConfirmation;
  }
  const passwordConfirmation = validateFieldConfirmation(
    values.password,
    values.password_confirmation
  );
  if (passwordConfirmation) {
    errors.password_confirmation = passwordConfirmation;
  }
  const passwordError = validatePassword(values.password);
  if (passwordError) {
    errors.password = passwordError;
  }
  if (!values.accepted_privacy_policy) {
    errors.accepted_privacy_policy = { type: 'privacyPolicy' };
  }

  errors.postal_code =
    validateMinMaxLength({
      value: values.postal_code,
      max: 16,
    }) || errors.postal_code;
  const hasErrors = Object.values(errors).some((e) => e && e.type);
  return { errors, hasErrors };
};

interface Params {
  onVerifyEmail: (callbackUrl: string) => void;
}

const useSignUpForm = ({ onVerifyEmail }: Params) => {
  const requiredFields: (keyof SignUpFormData)[] = useMemo(() => {
    return [
      'email',
      'email_confirmation',
      'password',
      'password_confirmation',
      'first_name',
      'last_name',
      'address',
      'city',
      'state',
      'postal_code',
      'country',
    ];
  }, []);
  const formData: SignUpFormData = {
    email: '',
    email_confirmation: '',
    password: '',
    password_confirmation: '',
    account_name: '',
    first_name: '',
    last_name: '',
    address: '',
    address2: '',
    phone: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    accepted_privacy_policy: false,
  };
  const [values, setValues] = useState(formData);
  const [errors, setErrors] = useState<FormErrors<SignUpFormData>>();
  const [hasErrors, setFormHasErrors] = useState(false);
  const [submitError, setSubmitError] = useState<string[]>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();
  const intl = useIntl();
  const isOnline = useOnline();
  const { checkRecaptcha } = useRecaptcha({
    action: 'registerForm',
    setIsSubmitting,
    setSubmitError,
  });

  useEffect(() => {
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
  }, [requiredFields, values]);

  const handleChange = handleFormChange({
    values,
    setValues,
    getExtras: (e) => {
      const { name, value } = e.target;
      // update "state/province" value if country changes
      const extras =
        name === 'country' && value != values.country ? { state: '' } : {};
      return extras;
    },
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    /* istanbul ignore next */
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHasSubmitted(true);
    if (setOfflineErrors({ intl, isOnline, setIsSubmitting, setSubmitError })) {
      return;
    }

    const token = await checkRecaptcha();
    if (!token) {
      return;
    }

    const csrfToken = await getCsrfToken();
    setSubmitError(undefined);
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
    if (_errors.hasErrors) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('formValidationErrors', intl)]);
      return;
    }

    try {
      const { password_confirmation, email_confirmation, ...data } = values;
      const response = await fetchPost({
        url: `/api/v3/users`,
        data: {
          ...data,
          recaptcha: token,
          csrfToken,
        },
      });
      if (response.ok) {
        const callbackUrl = router.query.orgId
          ? `/donate/${router.query.orgId}/payment`
          : undefined;
        const responseData: RegisterUserResponse = await response.json();
        const signInResponse = await signIn('credentials', {
          code: responseData.code,
          grant_type: 'authorization_code',
          redirect: false,
          callbackUrl,
        });
        if (signInResponse?.ok) {
          clearCache();
          clearUserSpecificSessionStorage();
          const url =
            callbackUrl && signInResponse.url
              ? signInResponse.url
              : '/account/profile';
          onVerifyEmail(url);
        } else {
          onVerifyEmail(callbackUrl || '/account');
        }
      } else {
        setIsSubmitting(false);
        setResponseErrorMessages({ response, setErrors, setSubmitError, intl });
      }
    } catch (err) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('processingError', intl)]);
    }
  };

  return {
    handleChange,
    values,
    handleSubmit,
    errors,
    hasErrors,
    hasSubmitted,
    isSubmitting,
    submitError,
  };
};

export default useSignUpForm;
