import { defineMessages, useIntl } from 'react-intl';
import Head from 'next/head';
import GuestRegistration from '../components/templates/user/guest-registration';
import RecaptchaWrapper from '@components/molecules/recaptcha-wrapper/recaptcha-wrapper';

export const titleMessage = defineMessages({
  title: {
    id: 'iBpLtb',
    defaultMessage: 'Your Information',
    description: 'Heading for registration form.',
  },
});

const GuestPage = () => {
  const intl = useIntl();
  return (
    <>
      <Head>
        <title>
          {`AdventistGiving - ${intl.formatMessage(titleMessage.title)}`}
        </title>
      </Head>
      <RecaptchaWrapper>
        <GuestRegistration />
      </RecaptchaWrapper>
    </>
  );
};
export default GuestPage;
