import { ReactNode } from 'react';
import { Text } from '../../atoms/text/text';
import FormSectionTooltip from '../form-section-tooltip/form-section-tooltip';
import { Heading } from '@components/atoms/heading/heading';

interface Props {
  title: string;
  tooltipTitle?: string;
  children?: ReactNode;
  className?: string;
}

const FormSectionHeader = ({
  title,
  tooltipTitle,
  children,
  className = '',
}: Props) => {
  return (
    <div className={`relative ${className}`}>
      <Heading variant="h4" as="h2" color="nad-blue">
        {title}
      </Heading>

      {tooltipTitle && (
        <FormSectionTooltip title={tooltipTitle}>
          <Text size="XS" color="nad-blue-2" className="font-sans">
            {children}
          </Text>
        </FormSectionTooltip>
      )}
    </div>
  );
};
export default FormSectionHeader;
