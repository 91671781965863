import { defineMessages, useIntl } from 'react-intl';
import Head from 'next/head';
import SignUp from '../components/templates/user/sign-up';
import RecaptchaWrapper from '@components/molecules/recaptcha-wrapper/recaptcha-wrapper';

export const titleMessage = defineMessages({
  title: {
    id: 'f8RNqa',
    defaultMessage: 'Create an Account',
    description: 'Title for sign-up/register page.',
  },
});

const RegisterPage = () => {
  const intl = useIntl();
  return (
    <>
      <Head>
        <title>
          {`AdventistGiving - ${intl.formatMessage(titleMessage.title)}`}
        </title>
      </Head>
      <RecaptchaWrapper>
        <SignUp />
      </RecaptchaWrapper>
    </>
  );
};
export default RegisterPage;
