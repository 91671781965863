import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SimpleTemplateContainer } from '..';
import { Button } from '../../atoms/button/button';
import { Link } from '../../atoms/link/link';
import {
  MailingAddress,
  PersonalInformation,
  CreatePassword,
} from '../../molecules/sing-up-forms';
import { getDonateFlowUrl } from './guest-registration';
import { titleMessage } from 'pages/register';
import useSignUpForm from '@hooks/use-sign-up-form';
import BackLink from '@components/molecules/back-link/back-link';
import FormSectionHeader from '@components/molecules/form-section-header/form-section-header';
import FormErrorMessage from '@components/molecules/form-error-message/form-error-message';
import { Heading } from '@components/atoms/heading/heading';
import Message from '@components/molecules/message/message';
import { IconUser } from '@components/atoms/icons';
import { passwordMessages } from '@components/molecules/input-password/input-password';
import { VerifyEmailForm } from '@components/organisms/verify-email-form/verify-email-form';

export const signupMessages = defineMessages({
  yourInformation: {
    id: 'iBpLtb',
    defaultMessage: 'Your Information',
    description: 'Heading for registration form.',
  },
  headingMailingAddress: {
    id: 'I+vLO9',
    description: 'Heading for mailing address section of form registration',
    defaultMessage: 'Mailing Address',
  },
  tooltipTitleMailing: {
    id: 'Gn1QAB',
    description: 'Tooltip title for mailing address section.',
    defaultMessage: 'Why do we need this?',
  },
  headingCreatePassword: {
    id: 'ubEXmW',
    description: 'Heading for create Password section of form registration',
    defaultMessage: 'Create Password',
  },
  tooltipTitlePassword: {
    id: 'nBC9as',
    description: 'Tooltip title for create password section.',
    defaultMessage: 'Password Strength',
  },
});

const SignUp = () => {
  const [callbackUrl, setCallbackUrl] = useState<string>();
  const [verifyEmail, setVerifyEmail] = useState(false);
  const intl = useIntl();
  const {
    values,
    handleChange,
    handleSubmit,
    hasSubmitted,
    errors,
    hasErrors,
    submitError,
    isSubmitting,
  } = useSignUpForm({
    onVerifyEmail: (callbackUrl) => {
      setVerifyEmail(true);
      setCallbackUrl(callbackUrl);
    },
  });
  const router = useRouter();
  const { orgId } = router.query;

  return (
    <SimpleTemplateContainer isContainer>
      {verifyEmail && values.email && (
        <div className="w-full relative">
          <div className="flex justify-right md:absolute left-0 top-2">
            <BackLink href={orgId ? `/donate/${orgId}/login` : undefined} />
          </div>
          <div className="container-xs sm:max-w-sm mt-4 md:mt-0">
            <VerifyEmailForm
              showCancel={false}
              newEmail={values.email}
              onSuccess={() => {
                router.push(callbackUrl || '/account');
              }}
            />
          </div>
        </div>
      )}
      {!verifyEmail && (
        <form
          method="post"
          onSubmit={handleSubmit}
          data-testid="sign-up-form"
          noValidate
        >
          <div className="w-full text-center relative">
            <div className="flex justify-right md:absolute left-0 top-2">
              <BackLink href={orgId ? `/donate/${orgId}/login` : undefined} />
            </div>
            <div className="container-xs mt-4 md:mt-0">
              <Message
                type="info"
                className="flex items-center justify-center gap-1"
              >
                <IconUser />
                <FormattedMessage
                  id="nLI1WI"
                  defaultMessage="Already have an account? <span><a>Sign in</a>.</span>"
                  description="Message above registration form."
                  values={{
                    a: (text) => (
                      <Link
                        href={getDonateFlowUrl('/login', orgId)}
                        className="underline"
                      >
                        {text}
                      </Link>
                    ),
                  }}
                />
              </Message>
            </div>
          </div>
          <Heading
            variant="h2"
            as="h1"
            color="nad-blue"
            className="my-8 text-center"
          >
            {intl.formatMessage(titleMessage.title)}
          </Heading>

          <div className="container-xs space-y-6">
            <FormSectionHeader
              title={intl.formatMessage(signupMessages.yourInformation)}
            />
            <div className="w-full flex flex-col mt-6">
              <PersonalInformation
                values={values}
                errors={errors}
                hasSubmitted={hasSubmitted}
                handleChange={handleChange}
              />
            </div>
            <FormSectionHeader
              className="!mt-10"
              title={intl.formatMessage(signupMessages.headingMailingAddress)}
              tooltipTitle={intl.formatMessage(
                signupMessages.tooltipTitleMailing
              )}
            >
              <FormattedMessage
                id="u17EGG"
                defaultMessage="We use your mailing address to send your end-of-year tax receipts from your donations."
                description="Help message for mailing address on create an account."
              />
            </FormSectionHeader>
            <MailingAddress
              values={values}
              errors={errors}
              hasSubmitted={hasSubmitted}
              handleChange={handleChange}
            />
            <FormSectionHeader
              className="!mt-10"
              title={intl.formatMessage(signupMessages.headingCreatePassword)}
              tooltipTitle={intl.formatMessage(
                signupMessages.tooltipTitlePassword
              )}
            >
              {intl.formatMessage(passwordMessages.passwordStrengthHelp)}
            </FormSectionHeader>
            <CreatePassword
              values={values}
              errors={errors}
              hasSubmitted={hasSubmitted}
              handleChange={handleChange}
            />
            <div className="!mt-10 space-y-6">
              <FormErrorMessage errors={submitError} />
              <Button
                isLoading={isSubmitting}
                disabled={isSubmitting || (hasSubmitted && hasErrors)}
                width="large"
                className="mx-auto block"
                data-testid="sign-up-submit"
              >
                <FormattedMessage
                  id="Vn0bwN"
                  defaultMessage="Sign Up"
                  description="Sign up button"
                />
              </Button>
            </div>
          </div>
        </form>
      )}
    </SimpleTemplateContainer>
  );
};

export default SignUp;
