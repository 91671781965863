import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { getCsrfToken, signIn } from 'next-auth/react';
import { useIntl } from 'react-intl';
import {
  FormErrors,
  getIntlErrorMessage,
  handleFormChange,
  setResponseErrorMessages,
  validateEmail,
  validateFieldConfirmation,
  validatePassword,
  validateRequiredFields,
  validateMinMaxLength,
} from './use-form-validation';
import { setOfflineErrors, useOnline } from './use-online';
import { useRecaptcha } from './use-recaptcha';
import { clearCache, useCurrentUser } from './use-user';
import { clearUserSpecificSessionStorage } from './use-tithe-form';
import { fetchPatch, fetchPost } from '@fetch/helpers';
import { RegisterUserResponse } from '@models/http/register-user-response';
import { RegisterGuestRequest } from '@models/http/register-guest-request';
import { CurrentUser } from '@models/user';

export interface GuestReigstrationFormData extends RegisterGuestRequest {
  tax_receipt: boolean;
  create_account: boolean;
  password: string;
  password_confirmation: string;
}

interface ValidationParams {
  requiredFields: (keyof GuestReigstrationFormData)[];
  values: GuestReigstrationFormData;
}

const requiredFieldsCreateAccount: (keyof GuestReigstrationFormData)[] = [
  'password',
  'password_confirmation',
];

const validate = ({ requiredFields, values }: ValidationParams) => {
  if (values.tax_receipt && values.create_account) {
    requiredFields = [...requiredFields, ...requiredFieldsCreateAccount];
  }
  let errors = validateRequiredFields(requiredFields, values);
  const email = validateEmail(values.email);
  if (email) {
    errors.email = email;
  }
  const emailConfirmation = validateFieldConfirmation(
    values.email,
    values.email_confirmation
  );
  if (emailConfirmation) {
    errors.email_confirmation = emailConfirmation;
  }
  if (values.tax_receipt && values.create_account) {
    const passwordConfirmation = validateFieldConfirmation(
      values.password,
      values.password_confirmation
    );
    if (passwordConfirmation) {
      errors.password_confirmation = passwordConfirmation;
    }
    const passwordError = validatePassword(values.password);
    if (passwordError) {
      errors.password = passwordError;
    }
  }
  if (!values.accepted_privacy_policy) {
    errors.accepted_privacy_policy = { type: 'privacyPolicy' };
  }

  errors.postal_code =
    validateMinMaxLength({
      value: values.postal_code || '',
      max: 16,
    }) || errors.postal_code;
  const hasErrors = Object.values(errors).some((e) => e && e.type);
  return { errors, hasErrors };
};

const initializeValues = (user?: CurrentUser, tax_receipt = true) => {
  const isGuest = user && user.status === 'guest';
  const data: GuestReigstrationFormData = {
    email: isGuest ? (user.email ?? '') : '',
    email_confirmation: isGuest ? (user.email ?? '') : '',
    first_name: isGuest ? (user.first_name ?? '') : '',
    last_name: isGuest ? (user.last_name ?? '') : '',
    address: isGuest ? (user.address ?? '') : '',
    address2: isGuest ? (user.address2 ?? '') : '',
    phone: isGuest ? (user.phone ?? '') : '',
    city: isGuest ? (user.city ?? '') : '',
    state: isGuest ? (user.state ?? '') : '',
    postal_code: isGuest ? (user.postal_code ?? '') : '',
    country: isGuest ? (user.country ?? '') : '',
    tax_receipt,
    create_account: false,
    password: '',
    password_confirmation: '',
    accepted_privacy_policy: isGuest
      ? (user.accepted_privacy_policy ?? '')
      : false,
  };
  return data;
};

interface Params {
  onVerifyEmail?: (url: string) => void;
}

const useGuestRegistrationForm = ({ onVerifyEmail }: Params) => {
  const requiredFields: (keyof GuestReigstrationFormData)[] = useMemo(() => {
    return [
      'email',
      'email_confirmation',
      'first_name',
      'address',
      'city',
      'state',
      'postal_code',
      'country',
    ];
  }, []);
  const requiredFieldsAlt: (keyof GuestReigstrationFormData)[] = useMemo(() => {
    return ['email', 'email_confirmation'];
  }, []);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [values, setValues] = useState(initializeValues());
  const [errors, setErrors] = useState<FormErrors<GuestReigstrationFormData>>();
  const [hasErrors, setFormHasErrors] = useState(false);
  const [submitError, setSubmitError] = useState<string[]>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: user } = useCurrentUser();
  const isUpdatingGuestAccount =
    !values.create_account && user?.status === 'guest';
  const router = useRouter();
  const isOnline = useOnline();
  const intl = useIntl();
  const { checkRecaptcha } = useRecaptcha({
    action: 'guestRegistration',
    setIsSubmitting,
    setSubmitError,
  });

  useEffect(() => {
    const _reqFields = values.tax_receipt ? requiredFields : requiredFieldsAlt;
    const _errors = validate({ requiredFields: _reqFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
  }, [requiredFields, requiredFieldsAlt, values]);

  useEffect(() => {
    if (hasInitialized) {
      return;
    }
    if (user) {
      const useTaxReceipt =
        sessionStorage.getItem('guest_tax_receipt') !== 'no';
      const values = initializeValues(user, useTaxReceipt);
      setValues(values);
      setHasInitialized(true);
    }
  }, [hasInitialized, user]);

  const handleChange = handleFormChange({
    values,
    setValues,
    getExtras: (e) => {
      const { name, value } = e.target;
      // update "state/province" value if country changes
      const extras =
        name === 'country' && value != values.country ? { state: '' } : {};
      return extras;
    },
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    /* istanbul ignore next */
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHasSubmitted(true);
    if (setOfflineErrors({ intl, isOnline, setIsSubmitting, setSubmitError })) {
      return;
    }

    const token = await checkRecaptcha();
    if (!token) {
      return;
    }

    const csrfToken = await getCsrfToken();
    setSubmitError(undefined);
    const _reqFields = values.tax_receipt ? requiredFields : requiredFieldsAlt;
    const _errors = validate({ requiredFields: _reqFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
    if (_errors.hasErrors) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('formValidationErrors', intl)]);
      return;
    }

    try {
      const {
        tax_receipt,
        first_name,
        last_name,
        email,
        email_confirmation,
        create_account,
        password,
        password_confirmation,
        accepted_privacy_policy,
        ...data
      } = values;
      const createAccount = create_account && tax_receipt;
      const noTaxReceiptData = {
        first_name,
        last_name,
        email,
        accepted_privacy_policy,
      };
      const updateUserNoTaxOverrides =
        isUpdatingGuestAccount && tax_receipt
          ? {}
          : {
              address: '',
              address2: '',
              phone: '',
              city: '',
              state: '',
              postal_code: '',
              country: '',
            };
      const baseData = tax_receipt
        ? { ...data, ...noTaxReceiptData, csrfToken }
        : { ...noTaxReceiptData, ...updateUserNoTaxOverrides, csrfToken };
      const response = createAccount
        ? await fetchPost({
            url: `/api/v3/users`,
            data: {
              ...baseData,
              password,
              recaptcha: token,
            },
          })
        : isUpdatingGuestAccount
          ? await fetchPatch({
              url: `/api/v3/current_user`,
              data: { ...baseData },
            })
          : await fetchPost({
              url: `/api/v3/users/guest`,
              data: { ...baseData, recaptcha: token },
            });
      if (response.ok) {
        sessionStorage.setItem('guest_tax_receipt', tax_receipt ? 'yes' : 'no');
        const callbackUrl = router.query.orgId
          ? `/donate/${router.query.orgId}/payment`
          : undefined;
        if (isUpdatingGuestAccount) {
          const url = callbackUrl ?? `/donate`;
          router.push(url);
          return;
        }
        const responseData: RegisterUserResponse = await response.json();
        const signInResponse = await signIn('credentials', {
          code: responseData.code,
          grant_type: 'authorization_code',
          redirect: false,
          callbackUrl,
        });
        if (signInResponse?.ok) {
          clearCache();
          clearUserSpecificSessionStorage();
          const url =
            callbackUrl && signInResponse.url ? signInResponse.url : '/donate';
          if (createAccount) {
            onVerifyEmail?.(url);
          } else {
            router.push(url);
          }
        } else {
          setIsSubmitting(false);
          setSubmitError([getIntlErrorMessage('processingError', intl)]);
        }
      } else {
        setIsSubmitting(false);
        setResponseErrorMessages({ response, setErrors, setSubmitError, intl });
      }
    } catch (err) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('processingError', intl)]);
    }
  };

  return {
    handleChange,
    values,
    handleSubmit,
    errors,
    hasErrors,
    hasSubmitted,
    isSubmitting,
    submitError,
  };
};

export default useGuestRegistrationForm;
