import { Popover } from '@headlessui/react';
import { ReactNode, useState, KeyboardEvent } from 'react';
import { IconInterrogation, IconInterrogationFill } from '../../atoms/icons';
import { Text } from '../../atoms/text/text';
import Sticky from '../sticky/sticky';

interface Props {
  title: string;
  children: ReactNode;
}

const FormSectionTooltip = ({ title, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHoveringButton, setIsHoveringButton] = useState(false);

  return (
    <Popover>
      <>
        <Popover.Button
          className="absolute top-[10px] right-0 flex items-center space-x-2 group"
          onMouseEnter={() => setIsHoveringButton(true)}
          onMouseLeave={() => setIsHoveringButton(false)}
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
            if (e.code === 'Space' || e.code === 'Enter') {
              setIsOpen(!isOpen);
            }
          }}
          data-testid="tooltip"
        >
          <Text
            size="XS"
            color="nad-alps-night"
            className="font-sans flex items-center"
          >
            <span className="hidden sm:inline">{title}</span>
          </Text>
          {!isOpen && !isHoveringButton && <IconInterrogation />}
          {(isOpen || isHoveringButton) && (
            <IconInterrogationFill color="nad-blue" />
          )}
        </Popover.Button>

        {isOpen && (
          <>
            <Popover.Panel
              static
              className="hidden lg:absolute lg:top-[10px] lg:left-full lg:block z-10 w-[340px] xl:w-[420px] px-6"
              data-testid="tooltip-content"
            >
              <Sticky closable={true} onClose={() => setIsOpen(false)}>
                {children}
              </Sticky>
            </Popover.Panel>
            {isOpen && (
              <Sticky
                closable={true}
                onClose={() => setIsOpen(false)}
                className="lg:hidden w-full mt-4"
              >
                {children}
              </Sticky>
            )}
          </>
        )}
      </>
    </Popover>
  );
};
export default FormSectionTooltip;
